import { createStore } from 'vuex'

export default createStore({
  state: {
    token: localStorage.getItem('qlt-web-token'),
    user: localStorage.getItem('qlt-web-user') ? JSON.parse(localStorage.getItem('qlt-web-user')) : {},
    defaultActive: localStorage.getItem('qlt-web-defaultActive') ? localStorage.getItem('qlt-web-defaultActive') : '/Home/Index',
    breadcrumbs: localStorage.getItem('qlt-web-breadcrumbs') ? localStorage.getItem('qlt-web-breadcrumbs') : []
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('qlt-web-token', token)
    },
    setUser(state, user) {
      state.user = user
      localStorage.setItem('qlt-web-user', JSON.stringify(user))
    },
    setDefaultActive(state, defaultActive) {
      state.defaultActive = defaultActive
      localStorage.setItem('qlt-web-defaultActive', defaultActive)
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
      localStorage.setItem('qlt-web-breadcrumbs', breadcrumbs)
    },
    logout(state) {
      state.token = ''
      state.user = {}
      state.access = []
      // remove storage
      localStorage.removeItem('qlt-web-token')
      localStorage.removeItem('qlt-web-user')
      localStorage.removeItem('qlt-web-defaultActive')
      localStorage.removeItem('qlt-web-breadcrumbs')
    }
  },
  actions: {
    LOGIN(context, payload) {
      context.commit('setToken', payload?.token)
      context.commit('setUser', payload)
    },
    LOGOUT(context, payload) {
      context.commit('logout')
    }
  },
  modules: {}
})
