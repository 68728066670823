import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    redirect: '/Home/Index',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: 'Index',
        name: 'Index',
        component: () => import('@/views/Index.vue')
      },
      {
        path: 'Discover',
        name: 'Discover',
        component: () => import('@/views/Discover.vue')
      },
      {
        path: 'Schemes',
        name: 'Schemes',
        component: () => import('@/views/Schemes.vue')
      },
      // {
      //   path: 'Orders',
      //   name: 'Orders',
      //   component: () => import('@/views/Orders.vue')
      // },
      {
        path: 'Videos',
        name: 'Videos',
        component: () => import('@/views/Videos.vue')
      },
      {
        path: 'Logistics',
        name: 'Logistics',
        component: () => import('@/views/Logistics.vue')
      },
      {
        path: 'Article',
        name: 'Article',
        component: () => import('@/views/Article.vue')
      },
      {
        path: 'Play',
        name: 'Play',
        component: () => import('@/views/Play.vue')
      },
      {
        path: 'Manage',
        name: 'Manage',
        component: () => import('@/views/Manage.vue'),
        redirect: '/Home/Manage/AddCustomer',
        children: [
          {
            path: 'Customers',
            name: 'Customers',
            component: () => import('@/views/Customers.vue')
          },
          {
            path: 'AddCustomer',
            name: 'AddCustomer',
            component: () => import('@/views/AddCustomer.vue')
          },
          {
            path: 'AllOrders',
            name: 'AllOrders',
            component: () => import('@/views/AllOrders.vue')
          },
          {
            path: 'ProCustomer',
            name: 'ProCustomer',
            component: () => import('@/views/ProCustomer.vue')
          }
        ]
      },
      {
        path: '404',
        name: '404',
        component: () => import('@/views/404.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const userInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { menus: [] }
  const menus = userInfo.menus
  let pass = true
  if (to.meta.auth) {
    const index = menus.findIndex(item => item.url === to.fullPath)
    if (index === -1) pass = false
  }
  if (pass) next()
  else next({ path: from.path })
})

export default router
