<template>
  <router-view />
</template>

<script setup>
import { setVarStyle } from '@/utils/tools'
import { onMounted } from 'vue'
onMounted(async () => {
  setVarStyle('--el-color-primary', '#3aa20f')
})
</script>

<style lang="less">
body {
  padding: 0;
  margin: 0;
}
</style>
