/**
 * 时间格式化输出
 * @param {any} dateVal 合法时间格式
 * @param {string} formatVal 格式化方式
 * @returns 已格式化时间
 */
export const dateFormat = (dateVal, formatVal = 'yyyy-MM-dd') => {
  if (dateVal === '' || dateVal === null) return ''
  // yyyy MM dd HH mm ss
  let time
  if (dateVal instanceof Date) {
    time = dateVal
  } else {
    time = new Date(dateVal)
  }
  const yyyy = time.getFullYear()
  const MM = (time.getMonth() + 1).toString().padStart(2, '0')
  const M = (time.getMonth() + 1).toString()
  const dd = time.getDate().toString().padStart(2, '0')
  const d = time.getDate().toString()
  const HH = time.getHours().toString().padStart(2, '0')
  const mm = time.getMinutes().toString().padStart(2, '0')
  const ss = time.getSeconds().toString().padStart(2, '0')
  return formatVal
    .replace('yyyy', yyyy)
    .replace('MM', MM)
    .replace('M', M)
    .replace('dd', dd)
    .replace('d', d)
    .replace('HH', HH)
    .replace('mm', mm)
    .replace('ss', ss)
}
/**
 * 判断给定字符串能否JSON解析
 * @param {string} str 待校验字符串
 * @returns 校验结果
 */
export const isJSON = (str) => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str)
      if (typeof obj === 'object') return true
      return false
    } catch (e) {
      return false
    }
  }
  return false
}

/**
   * 数组根据p_id转树形结构
   * @param list 待转数组
   * @param id 数组id
   * @param pid 数组转树的父id
   * @param child 期望输出的子项字段名
   * @returns
   */
export const list2tree = (list, id = 'id', pid = 'parent_id', child = 'children') => {
  // console.log(list)
  const list1 = JSON.parse(JSON.stringify(list))
  const treeMap = {}
  list1.forEach((item) => { // 列表转对象
    treeMap[item[id]] = item
  })
  const tree = []
  list1.forEach((item) => {
    if (treeMap[item[pid]]) { // 在树对象中找到数据
      if (!treeMap[item[pid]][child]) treeMap[item[pid]][child] = []
      treeMap[item[pid]][child].push(item)
    } else {
      tree.push(item)
    }
  })
  tree.sort((a, b) => a.sort - b.sort)
  return tree
}

/**
   * 获取cookie
   * @param {string} key
   * @returns
   */
export const getCookie = (key) => {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(`${key}=`)
    if (start !== -1) {
      start += key.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return atob(decodeURI(document.cookie.substring(start, end)))
    }
  }
  return ''
}

/**
 * 删除cookie
 * @param {string} key
 */
export const delCookie = (key) => {
  const exp = new Date()
  exp.setTime(exp.getTime() - 1)
  const cval = getCookie(key)
  if (cval != null) {
    document.cookie = `${key}=${cval};expires=${exp.toUTCString()}`
  }
}

/**
 * 设置cookie
 * @param {string} key
 * @param {string} value
 * @param {number} expireMsec
 */
export const setCookie = (key, value, expireMsec) => {
  const exdate = new Date()
  exdate.setTime(exdate.getTime() + expireMsec)
  document.cookie = `${key}=${btoa(encodeURI(value))}${(expireMsec == null) ? '' : `;expires=${exdate.toUTCString()}`}`
}
/**
 * 修改样式
 * @param {*} k 键
 * @param {*} v 值
 * @param {*} dom DOM
 */
export const setVarStyle = (k, v, dom = document.documentElement) => {
  dom.style.setProperty(k, v)
}
